<template>
    <div>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">

                    <div class="top_sort">
                        <div class="auth">
                            <span>认证</span>
                            <el-button :type="QueryFurnitureLibrary.goodSort==0?'primary':'info'" plain size="mini" @click="setGoodSort(0)">全部</el-button>
                            <el-button :type="QueryFurnitureLibrary.goodSort==1?'primary':'info'" plain size="mini" @click="setGoodSort(1)">实名认证</el-button>
                        </div>
                        <div class="auth">
                            <span>类型</span>
                            <el-button v-for="(v,i) of buyAskCategory" :key="i" :type="QueryFurnitureLibrary.askCategoryId==v.id?'primary':'info'" plain size="mini" @click="setAskCategoryId(v,i)">{{v.categoryName}}</el-button>
    
                        </div>
                        <div class="auth">
                            <span>预缴款</span>
                            <el-button :type="QueryFurnitureLibrary.goodSort==0?'primary':'info'" plain size="mini" @click="setGoodSort(0)">全部</el-button>
                            <el-button :type="QueryFurnitureLibrary.goodSort==1?'primary':'info'" plain size="mini" @click="setGoodSort(1)">有预缴</el-button>
                            <el-button :type="QueryFurnitureLibrary.goodSort==2?'primary':'info'" plain size="mini" @click="setGoodSort(2)">无预缴</el-button>
                        </div>
                        <div class="auth">
                            <span>排序</span>
                            <el-button :type="QueryFurnitureLibrary.goodSort==0?'primary':'info'" plain size="mini" @click="setGoodSort(0)">按时间正序</el-button>
                            <el-button :type="QueryFurnitureLibrary.goodSort==1?'primary':'info'" plain size="mini" @click="setGoodSort(1)">按时间倒序</el-button>
                            <!-- <el-button :type="QueryFurnitureLibrary.goodSort==1?'primary':'info'" plain size="mini" @click="setGoodSort(1)">价格</el-button> -->
                            <!-- <el-button :type="QueryFurnitureLibrary.goodSort==2?'primary':'info'" plain size="mini" @click="setGoodSort(2)">时间</el-button> -->
                            <!-- <el-button :type="QueryFurnitureLibrary.goodSort==3?'primary':'info'" plain size="mini" @click="setGoodSort(3)">库存</el-button> -->
                            <!-- <el-button :type="QueryFurnitureLibrary.goodSort==4?'primary':'info'" plain size="mini" @click="setGoodSort(4)">浏览量</el-button> -->
                        </div>
                    </div>
                    <div class="middle_middle">
         <!--                <div style="float:left;margin:12px;">
                            <span>价格</span>
                            <el-input size="mini" v-model.number="QueryFurnitureLibrary.priceMin"></el-input>
                            <span>—</span>
                            <el-input size="mini" v-model.number="QueryFurnitureLibrary.priceMax"></el-input>
                            <el-button type="primary" size="mini" @click="search">确定</el-button>
                        </div> -->
                        <div class="wangge" @click="ChangeType(2)">
                            <img src="@/assets/icon/liebiao.png" class="hover" alt="" :style="type==2?'display:none':'display:block'">
                            <img src="@/assets/icon/liebiao_hover.png" alt="" :style="type==2?'display:block':'display:none'">
                            <span :style="type==2?'color:#2090ff':''">列表模式</span>
                        </div>
                        <div class="wangge" @click="ChangeType(1)">
                            <img src="@/assets/icon/wangge_hover.png" class="hover" alt="" :style="type==1?'display:none':'display:block'">
                            <img src="@/assets/icon/wangge.png" alt="" :style="type==1?'display:block':'display:none'">
                            <span :style="type==1?'color:#2090ff':''">网格模式</span>
                        </div>
                    </div>
                    <div class="text-left">
                        <!-- @click="$oucy.go('/askBuyDetail?id='+v.id)"  -->
                        <div class="" v-if="type==1">
                            <div class="box1s">
                                <div class="box1Box" v-for="(v,i) of queryFurnitureLibraryObj.content" 
                                @click="checkAuth(v)"
                                :key="v.id">
                                    <div class="box1">
                                        <div style="width:200px;height:200px;background-color: #f1f1f1;margin-bottom: 10px;">
                                              <template v-for="(vvv,i) of v.buyAskForms" v-if="vvv.buyAskCategoryForm && vvv.buyAskCategoryForm.formType == 7" >
                                                <template v-for="(vv,ii) of vvv.value" v-if="ii<1">
                                                    <img :src="$oucy.ossUrl+ vv.value" class="bn" v-if="vv.type=='image'">
                                                    <video :src="$oucy.ossUrl+ vv.value" class="bn" v-if="vv.type=='video'"></video>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="line-1"><span class="tag m-r-5"> {{v.ltBuyAskCategoryDto.categoryName}}</span><span class="title" v-html="v.askTitle"></span></div>
                                        <div class="f12 m-t-10" v-for="(vv,ii) of v.buyAskForms" :key="ii" v-if="ii<2 && vv.buyAskCategoryForm.formType!=8 && vv.buyAskCategoryForm.formType!=7"><span class="c-2 m-r-10">{{vv.buyAskCategoryForm.formTitle}}</span>
                                            <span class="c-6" v-if="vv.buyAskCategoryForm.formType == 1">{{vv.value?'是':'否'}}</span>
                                            <span class="c-6" v-else-if="vv.buyAskCategoryForm.formType == 6">{{vv.value.addr}}</span>
                                            <span class="c-6">{{vv.value}}</span>
                                        </div>
                                        <div class="f12 m-t-10"><span class="c-2 m-r-10">发布时间</span><span class="c-6">{{v.askLatestTime}}</span></div>
                                        <div class="df-ai-c m-t-10 line-1">
                                            <template v-if="v.askAnonymityStatus">
                                                <img  src="https://aliyunoss.lesoujia.com/lsj_mobile/image/niming.png" class="pic m-r-10"><span class="name f12 c-6">匿名发布</span>
                                            </template>
                                            <template v-else>
                                                <img :src="$oucy.ossUrl +v.detailAvatar" class="pic m-r-10"><span class="name f12 c-6">{{v.detailNickname}}</span>
                                            </template>
                                        </div>
                                        <div class="m-t-10 df-jc-s-b">
                                            <el-button size="mini" type="primary" @click.stop="checkAuth(v)">评论抢单</el-button>
                                            <el-button size="mini" type="success" @click.stop="openImMessage(v)" v-if="!v.askAnonymityStatus">在线聊天</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="" v-if="type==2">
                            <div class="box2s">
                                <div class="box2  df-jc-s-b" v-for="(v,i) of queryFurnitureLibraryObj.content" :key="i" @click="$oucy.go('/askBuyDetail?id='+v.id)">
                                    <div class="df">
                                        <div style="width:96px;height:96px;background-color: #f1f1f1;">
                                            <template v-for="(vvv,i) of v.buyAskForms" v-if="vvv.buyAskCategoryForm && vvv.buyAskCategoryForm.formType == 7" >
                                                <template v-for="(vv,ii) of vvv.value" v-if="ii<1">
                                                    <img :src="$oucy.ossUrl+ vv.value" class="bn2" v-if="vv.type=='image'">
                                                    <video :src="$oucy.ossUrl+ vv.value" class="bn2" v-if="vv.type=='video'"></video>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="m-l-40">
                                            <div class="line-1"><span class="tag m-r-5"> {{v.ltBuyAskCategoryDto.categoryName}}</span><span class="title" v-html="v.askTitle"></span></div>
                                            <div class="df m-t-10">
                                                <div v-for="(vv,ii) of v.buyAskForms" :key="ii" v-if="ii<3 && vv.buyAskCategoryForm.formType!=8 && vv.buyAskCategoryForm.formType!=7" class="f12 m-r-50"><span class="c-2 m-r-10">{{vv.buyAskCategoryForm.formTitle}}</span>
                                                        <span class="c-6" v-if="vv.buyAskCategoryForm.formType == 1">{{vv.value?'是':'否'}}</span>
                                        <span class="c-6" v-else-if="vv.buyAskCategoryForm.formType == 6">{{vv.value.addr}}</span>
                                        <span class="c-6">{{vv.value}}</span>
                                                </div>
                                            </div>
                                            <div class="df-ai-c m-t-10 line-1">
                                                <template v-if="v.askAnonymityStatus">
                                                    <img  src="https://aliyunoss.lesoujia.com/lsj_mobile/image/niming.png" class="pic m-r-10"><span class="name f12 c-6">匿名发布</span>
                                                </template>
                                                <template v-else>
                                                    <img :src="$oucy.ossUrl +v.detailAvatar" class="pic m-r-10"><span class="name f12 c-6">{{v.detailNickname}}</span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="df">
                                        <div class="f12 c-6 m-r-40">
                                            {{v.askLatestTime}}发布
                                        </div>
                                        <div class="m-t-10">
                                            <el-button size="mini" type="primary" @click.stop="checkAuth(v)">评论抢单</el-button>
                                            <el-button size="mini" type="success" @click.stop="openImMessage(v)" v-if="!v.askAnonymityStatus">在线聊天</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            <div class="pagination">
                <el-pagination v-if="queryFurnitureLibraryObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj.size" :total="queryFurnitureLibraryObj.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" :page-sizes="pageSizes" @next-click="currentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { furnitureGlobalAttr, globalClassify, spu, buyAskCategory, buyAsk,userAuth } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";

export default {
    name: "Furniture",
    data() {
        return {
            type: 1,
            allGlobalClassifyList: [],
            allFurnitureGlobalAttrs: [],
            queryFurnitureLibraryObj: {},
            globalAttrOption: [],
            // 请求对象
            QueryFurnitureLibrary: {
                askTitle: null,
                askCategoryId: null,
                limit: 20,
                start: 0,
                askAuditStatus: 2,
                goodSort: 0,
                priceMax: null,
                priceMin: null,
                siteId: null, //站点

            },
            pageSizes: [20, 40, 100],
            buyAskCategory: [{ categoryName: '全部求购', id: null }],

        }
    },
    watch: {
        $route: {
            handler(to, from) {
                this.QueryFurnitureLibrary.askTitle = to.query.keyword || null
                this.QueryFurnitureLibrary.start=0
                this.queryFurnitureLibraryObj ={}
                this.search()
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        // this.getAllGlobalClassify()
        this.getAllFurnitureGlobalAttrs()
        this.search()
        let siteId = localGet('siteId')
        if (siteId) {
            this.QueryFurnitureLibrary.siteId = siteId
        }
        this.getAllBuyAskCategory()

    },
    methods: {
        getAllBuyAskCategory() {
            buyAskCategory.getAllBuyAskCategory().then(res => {
                this.buyAskCategory.push(...res)
                // this.QueryFurnitureLibrary.askCategoryId = res[0].id

            })
        },


        ChangeType(num) {
            this.type = num;
        },
        // 查询全局分类树
        getAllGlobalClassify() {
            globalClassify.getAllGlobalClassify({ globalClassifyEnum: 0 }).then(res => {
                this.allGlobalClassifyList = res || []
                this.allGlobalClassifyList.unshift({ classifyName: "全部分类", id: null })
                this.$oucy.keyAs(this.allGlobalClassifyList, 'classifyName')

            })
        },
        // 查询所有的属性不分页
        getAllFurnitureGlobalAttrs() {
            furnitureGlobalAttr.getAllFurnitureGlobalAttrs({ category: 0 }).then(res => {
                if (res) {
                    this.allFurnitureGlobalAttrs = res
                    this.globalAttrOption = []
                    for (let v of res) {
                        this.globalAttrOption.push(null)
                    }
                }
            })
        },
        search() {
            if (this.QueryFurnitureLibrary.askTitle=='null') {
                this.QueryFurnitureLibrary.askTitle=null
            }
            this.$oucy.setTitle(`搜求购 ${this.QueryFurnitureLibrary.askTitle}`)
            buyAsk.searchAllBuyAsk(this.QueryFurnitureLibrary).then(res => {
                if (res && res.content) {

                    for (let v of res.content) {
                        if (v.buyAskForms) {
                            for (let vv of v.buyAskForms) {

                                if (vv.buyAskCategoryForm.formType == 7 && vv.value && typeof vv.value == 'string') {

                                    vv.value = JSON.parse(vv.value)

                                }
                            }
                        }
                    }

                }
                this.queryFurnitureLibraryObj = res || {}
                console.log(this.queryFurnitureLibraryObj)
            })
        },
        // 切换菜单回调
        menuSelect(v) {
            console.log(v)
            this.QueryFurnitureLibrary.globalClassId = v
            this.search()
        },
        // 排序
        setGoodSort(v) {
            this.QueryFurnitureLibrary.goodSort = v
            this.search()
        },
        // 类型
        setAskCategoryId(v) {
            this.QueryFurnitureLibrary.askCategoryId = v.id
            this.search()
        },
        // 属性
        setGlobalAttrOption(v, i, vv, ii) {
            if (vv) {
                this.$set(this.globalAttrOption, i, vv.id)
            } else {
                this.$set(this.globalAttrOption, i, null)
            }
            let globalAttrOption = []
            for (let v of this.globalAttrOption) {
                if (v) {
                    globalAttrOption.push(v)
                }
            }
            this.QueryFurnitureLibrary.globalAttrOption = JSON.stringify(globalAttrOption)
            this.search()
        },
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.search();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.search();
        },
        openImMessage(v){
            this.loginUser = localGet('loginUser')
            if(this.loginUser && this.loginUser.id){
                userAuth.queryUserBuyAskActionIsAuth({
                    buyAskActionEnum: 1 // 求购操作类型 1聊天 2拨号 3查看
                }).then((res) => {
                    if(res) {
                        this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {targetUserId:v.askUser});
                    } else {
                        this.$alert(res)  
                    }
                    
                })  
            }else{
                this.$alert('请先登录','提示')
            }
            // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
        },

        checkAuth(value){
            userAuth.queryUserBuyAskActionIsAuth({
                buyAskActionEnum: 3 // 求购操作类型 1聊天 2拨号 3查看
            }).then((res) =>{ 
            if(res) {
                this.$oucy.go('/askBuyDetail?id='+value.id)
            } else {
                this.$alert(res) 
            }
            })
        }
    }

}
</script>
<style scoped>
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    min-height: 500px;
    padding: 30px 0;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
}

.top_sort {
    margin-top: 20px;
    /*margin-left: 10px;*/
    background-color: white;
    padding: 30px 0 5px 20px;
}

.auth {
    display: flex;
    margin-bottom: 30px;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
}

.auth .el-button--text.select {
    color: #409EFF;
}

.el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
}

.el-button--mini {
    border: none;
}

.el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.middle_middle {
    height: 52px;
    line-height: 52px;
    background-color: #FFFFFF;
    margin: 10px 0 10px ;
}

.middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
}

.middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
}

.list>>>.el-table__row>td {
    /*去除表格线*/
    border: none;
}

.list>>>.el-table th.is-leaf {
    /*去除上边框*/
    border: none;
}

.list>>>.el-table::before {
    /*去除下边框*/
    height: 0;
}

.wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.box1s {
    display: flex;
    /*justify-content: space-around;*/
    flex-wrap: wrap;
    background-color: #fff;
}
.box1Box{
    width: 20%;
}
.box1 {
    /*width: 200px;*/
    padding: 20px;
    cursor: pointer;
}

.bn {
    width: 100%;
    height: 200px;
}

.tag {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #FFFFFF;
    background: #E95F29;
    border-radius: 2px;
    display: inline-block;
    padding: 4px 7px;
}

.title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
}

.pic {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    /*margin-right: 10px;*/
}
.box2s{
    background-color: #fff;
}
.box2{
    padding: 20px;
    cursor: pointer;
    
}
.bn2{
    width: 96px;
    height: 96px;
}
.pagination {
    margin-top: 50px;
    line-height: 150px;
}
</style>